import axios from "axios";
import { store } from "../store";

export interface AirportInterface {
  id: number;
  name: string;
  longitude: string;
  latitude: string;
  icao_code: string;
  service_providers: ServiceProviderInterface[];
}

export interface ServiceProviderInterface {
  id: number;
  name: string;
}

interface AirportRequestInterface {
  results: AirportInterface[];
}

const {
  REACT_APP_ICELINK_CLIENT_ID,
  REACT_APP_ICELINK_CLIENT_SECRET,
  REACT_APP_ICELINK_ENDPOINT,
} = process.env;

// Unnecessary now?
export function fetchToken() {
  if (!REACT_APP_ICELINK_CLIENT_ID || !REACT_APP_ICELINK_CLIENT_SECRET) {
    console.warn(
      "Missing REACT_APP_ICELINK_CLIENT_ID or REACT_APP_ICELINK_CLIENT_SECRET"
    );
    return Promise.reject("No client credentials supplied");
  }
  return axios
    .post(
      `${REACT_APP_ICELINK_ENDPOINT}/api/o/token/`,
      { grant_type: "client_credentials" },
      {
        auth: {
          username: REACT_APP_ICELINK_CLIENT_ID,
          password: REACT_APP_ICELINK_CLIENT_SECRET,
        },
      }
    )
    .then(({ data }) => data.access_token);
}

export async function getAirportList() {
  // const token = await fetchToken();
  const token = window.localStorage.getItem("access_token");

  const {
    data,
  }: {
    data: AirportRequestInterface;
  } = await axios.get(
    `${REACT_APP_ICELINK_ENDPOINT}/api/1/airports-icedash/?page_size=100000`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  const result = data.results.reduce((prev, initial) => {
    return {
      ...prev,
      [initial.id]: initial,
    };
  }, {});
  store.dispatch({ type: "@airports/info/update", data: result });
}

import axios from "axios";
import qs from "qs";
import { processLinkHeaders } from "../../common/services/utils";
import { refreshAccessToken } from "../../common/services/auth"; /* eslint-disable-line */

const { REACT_APP_ICELINK_ENDPOINT } = process.env;

let isRefreshing = false;
const refreshSubscribers = [];

const http = axios.create({
  baseURL: REACT_APP_ICELINK_ENDPOINT,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
});

const onRefreshed = () => {
  refreshSubscribers.forEach(cb => cb());
};

const subscribeTokenRefresh = cb => {
  refreshSubscribers.push(cb);
};

const checkToken = async response => {
  const { config } = response;
  const retryOriginalRequest = new Promise(resolve => {
    subscribeTokenRefresh(() => {
      config.headers.Authorization = `Bearer ${window.localStorage.getItem("access_token")}`;
      resolve(axios(config));
    });
  });
  if (!isRefreshing) {
    isRefreshing = true;
    try {
      await refreshAccessToken();
      isRefreshing = false;
      onRefreshed();
      return retryOriginalRequest;
    } catch (e) {
      if (window.location.pathname && window.location.pathname !== "/") {
        window.location.assign("/");
      }
      return Promise.reject(e);
    }
  }
  return retryOriginalRequest;
};

export const handleError = (status, error) => {
  const { response, data } = error;
  // If status is 401 check if the token needs to be refreshed,
  const isAuthUrl = /\/api\/o\/token\//.test(response.config.url);
  if (status === 401 && !isAuthUrl) {
    return checkToken(response);
  }
  if (status === 403 && data && data.error.message === "Change password before proceeding") {
    window.location.href = "/admin/#/settings/?s=password";
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

// Interceptors
http.interceptors.request.use(c => {
  const config = { ...c };
  const token = window.localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

http.interceptors.response.use(
  response => {
    const { data } = response;
    if (data.error && data.status) {
      return handleError(data.status, { response, data });
    }
    return response;
  },
  error => {
    if (error.response) {
      const { status } = error.response;
      return handleError(status, error);
    }
    return Promise.reject(error);
  }
);

export const fetchPaginatedData = async (url, params = {}) => {
  const resp = await http.get(url, { params });
  const { next } = processLinkHeaders(resp.headers);
  if (next) {
    const nextData = await fetchPaginatedData(url, { ...params, page: next.params.page });
    return [...(resp.data || []), ...(nextData || [])];
  }
  return resp.data || [];
};

export const fetchCachedData = async (name, getDataFunc) => {
  name = `http-cache-${name}`;
  let res = JSON.parse(localStorage.getItem(name) || "null");
  if (!res) {
    res = await getDataFunc();
    localStorage.setItem(name, JSON.stringify(res));
  }
  return res;
};

export const cleanCachedData = () =>
  Object.keys(localStorage).forEach(key => {
    if (key.indexOf("http-cache-") === 0) {
      localStorage.removeItem(key);
    }
  });

export default http;

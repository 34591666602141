import { combineReducers } from 'redux';
import { airports } from './airports';

export interface AirportsState {
  realtime: any
  info: any
  environment: any
}

export interface AppState {
  airports: AirportsState
}

export const defaultReducers = combineReducers({
  airports
})
import React from 'react';
import { NavLink } from 'react-router-dom';
import './bottom-bar.component.scss';

export class BottomBar extends React.Component {
  state = {};
  render(){
    return (
      <div className="bottom-bar">
        <NavLink exact to="/production">Production</NavLink>
        <NavLink exact to="/training">Training</NavLink>
      </div>
    );
  }
}
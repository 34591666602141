import React from "react";
import { isObject } from "lodash";
import { normalizeArray } from "../../common/services/utils";

const FormGroup = ({ label, children, htmlFor, error, className }) => {
  const classNames = ["form-group"];
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(" ")}>
      {label && <label htmlFor={htmlFor || null}>{label}</label>}
      {children || null}
      {error &&
        normalizeArray(error).map((e, index) => (
          <p key={index} className="text-danger m-0 mb-1 mt-1">
            {isObject(e) ? e.message || "" : e}
          </p>
        ))}
    </div>
  );
};

export default FormGroup;


export default function me(state = { airport: {} }, action) {
  switch (action.type) {
    case "SET_CURRENT_AIRPORT":
      return {
        ...state,
        airport: action.data
      };
    case "SET_LOCATIONS":
      return {
        ...state,
        locations: (action.data || []).filter(({ is_active }) => is_active),
        nonActiveLocations: (action.data || []).filter(({ is_active }) => !is_active)
      };
    case "SET_CURRENT_USER":
      return {
        ...state,
        user: action.data
      };
    case "SET_AIRPORTS":
      return {
        ...state,
        airports: action.data
      };
    case "SET_AIRLINES":
      return {
        ...state,
        airlines: action.data
      };
    case "SET_AC_TYPES":
      return {
        ...state,
        aircraft_types: action.data
      };
    case "SET_GROUPS":
      return {
        ...state,
        groups: action.data
      };
    case "SET_LAYOUTS":
      return {
        ...state,
        layouts: action.data
      };
    case "SET_DEFAULT_TREATMENT":
      window.localStorage.setItem("default-labels", JSON.stringify(action.data.labels || []));
      window.localStorage.setItem("default-pre-spray", Number(action.data.preSpray) || 0);

      return {
        ...state,
        treatments: action.data
      };
    case "SET_STATION":
      return {
        ...state,
        station: action.data
      };
    case "SET_DARK_MODE":
      window.localStorage.setItem("darkMode", action.data);
      return {
        ...state,
        darkMode: action.data
      };
    case "SET_SERVICE_PROVIDER":
      return {
        ...state,
        service_provider: action.data
      };
    case "SET_STATION_WEATHER":
      return {
        ...state,
        station_weather: action.data
      };
    default:
      return state;
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter, Route } from 'react-router-dom';
import './index.scss';

import * as serviceWorker from './serviceWorker';
import { store } from './store';
import {FlightstripContainer} from './containers/flightstrip.container';
import Signin from './signin/components/signin'; // Import the updated Signin component

ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <Route path="/" exact component={Signin} /> {/* Render the Signin component when the user lands on the website */}
      <Route path="/production" exact
        render={(props: any) => <FlightstripContainer environment="production" {...props} />}/>
      <Route path="/training" exact
        render={(props: any) => <FlightstripContainer environment="training" {...props} />}        />
    </Provider>

  </HashRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

import React from "react";
import "./flightstrip.container.scss";
import { FlightstripCounter } from "../components/counter.component";
import { getAirportList } from "../services/airports.service";
import { AirportList } from "../components/airport-list.component";
import { BottomBar } from "../components/bottom-bar.component";
import { logoutUser } from "../common/services/auth";
// @ts-ignore
import FlightstripWorker from "../workers/firebase.worker";
import { store } from "../store";

interface FlightstripsContainerInterface {
  environment: string;
}


export class FlightstripContainer extends React.Component<
  FlightstripsContainerInterface
> {
  state = {
    airports: {}
  };
  componentDidMount() {
    this.startWatchers();
    getAirportList();
  }
  startWatchers = () => {
    const { environment = "production" } = this.props;

    const worker = new FlightstripWorker();
    worker.postMessage(environment);
    worker.addEventListener("message", (event: any) => {
      store.dispatch({
        type: "@airports/realtime/update",
        ...event.data
      });
    });
  };

  handleLogout = () => {
    logoutUser();
  };

  render() {
    const { environment = "production" } = this.props;
    return (
      <React.Fragment>
      <div className="flightstrips-container">
        <div className="counter-container">
          <div style={{ backgroundColor: 'black', padding: '10px', width: '100%'}} >
            <button className="logout" onClick={this.handleLogout}>Logout</button>
            </div>
          <FlightstripCounter environment={environment} />
        </div>
        <div className="list-container">
          <AirportList environment={environment} />
        </div>
      </div>
      <BottomBar/>
      </React.Fragment>
    );
  }
}

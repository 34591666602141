import "babel-polyfill";
import "../styles/theme.scss"
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import transform from "lodash/transform";
import isEqual from "lodash/isEqual";
import { Duration, DateTime } from "luxon";
import queryString from "query-string";
import React from "react";
import { render as r } from "react-dom";
import { Provider } from "react-redux";
import { createStore /* , applyMiddleware */ } from "redux";
import urlParse from "url-parse";
import constants from "../../common/constants";
import rootReducer from "../reducers";

/*
// Uncomment this block for performance testing against redux actions
// When this middleware is applied, it shows actions in the performance tab of DevTools (User Timing section)
const userTiming = () => next => action => {
  if (performance.mark === undefined) return next(action);
  performance.mark(`${action.type}_start`);
  const result = next(action);
  performance.mark(`${action.type}_end`);
  performance.measure(`${action.type}`, `${action.type}_start`, `${action.type}_end`);
  return result;
};
*/

const store = createStore(
  rootReducer,
  // applyMiddleware(userTiming) to see redux actions in the browser performance profiler
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__() /* eslint no-underscore-dangle: 0 */
);


/* istanbul ignore next */
const render = (Component, id = "root") => {
  const props = {
    location: {
      ...window.location,
      params: queryString.parse(window.location.search),
    },
    getLocationParams: location => queryString.parse(location ? location.search : window.location.search),
  };

  r(
    <Provider store={store}>
      <Component {...props} />
    </Provider>,
    document.getElementById(id)
  );
};

const round = (number, precision = 2) => {
  if (number === null || number === "") {
    return null;
  }
  const factor = 10 ** precision;
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
};


const fluidFromServer = (value, no_round, unit) => {
  const { airport = {} } = store.getState().me || {};
  if (value <= 0) {
    return 0;
  }
  unit = unit || (airport && airport.unit_of_measure) || "GAL";
  const result = value
    ? unit.toLowerCase() === "gal"
      ? value / 378.5411784
      : value / 100
    : undefined; /* eslint no-nested-ternary: 0 */
  return no_round ? result : round(result, 1);
};

const fluidToServer = (value, unit) => {
  const { airport = {} } = store.getState().me || {};
  if (value <= 0) {
    return 0;
  }
  unit = unit || (airport && airport.unit_of_measure) || "GAL";
  const result = value
    ? unit.toLowerCase() === "gal"
      ? value * 378.5411784
      : value * 100
    : undefined; /* eslint no-nested-ternary: 0 */
  return Math.round(result);
};

const normalizeArray = array => (Array.isArray(array) ? array : [array]);

const processLinkHeaders = headers => {
  if (!headers) return {};
  const RequestLinks = headers.Link || headers.link || null;
  if (!RequestLinks) return {};
  const UnprocessedLinks = RequestLinks.split(",");
  const linkHeaders = {};
  UnprocessedLinks.forEach(l => {
    const rel = /rel="?(.+?)"?$/g.exec(l);
    const link = /<(.*?)>/g.exec(l);
    const parsedLink = urlParse(link[1]);
    linkHeaders[rel[1]] = {
      pathName: parsedLink.pathname,
      params: queryString.parse(parsedLink.query),
    };
  });
  return linkHeaders;
};

export {
  render,
  store,
  round,
  fluidFromServer,
  fluidToServer,
  normalizeArray,
  processLinkHeaders,
};

import { normalizeArray, store } from "../../services/utils";
import Constants from "../../constants";

export function hasPermission(user, permissions) {
  const constantPermissions = Constants.user.permissions || {};
  if (!user || !user.groups) {
    return false;
  }
  if (user.role === 'airport' && permissions.includes('airport')) {
    return true;
  }
  return !!user.groups.find(g => normalizeArray(permissions).find(p => constantPermissions[p] === g));
}

export function hasJcaiiInternalUserPermission(user) {
  if (!user) {
    return false;
  }
  if (
    user.role === "jcaii"
  ) {
    return true;
  }
  return false;
}

const HasPermission = ({ permissions = [], disabled = false, children }) => {
  const { user } = store.getState().me || {};
  if (!disabled && user && hasPermission(user, permissions)) {
    return !children ? null : children;
  }
  return null;
};

export default HasPermission;

import React from "react";
import { connect } from "react-redux";
import "./airport-list.component.scss";
import { ServiceProviderModal } from "./service-provider-modal.component";
import { AppState } from "../reducers";

interface Airport {
  count: number;
  id: number;
}
interface AirportData {
  name: string;
}

const listContent = (
  component: React.Component,
  airport: Airport,
  airportId: string,
  airportData: AirportData
) => {
  return (
    <div
      className="list-item"
      key={airportId}
      onClick={() =>
        component.setState({
          selectedAirport: {
            realtime: airport,
            info: airportData
          }
        })
      }
    >
      <div className="airport-name">
        {airportData ? airportData.name : airport.id}
      </div>
      <svg viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"
        />
      </svg>
      <div className="counter">{airport.count || 0}</div>
    </div>
  );
};

interface AirportListProps {
  environment: string;
  realtime: any;
  info: any;
}

class AirportListComponent extends React.Component<AirportListProps> {
  state = { selectedAirport: null };
  render() {
    const { environment, realtime, info } = this.props;
    const airportData = realtime[environment] || {};
    const airports = Object.entries(airportData).filter(
      ([airportId, airport]: [string, any]) => airport.count > 0
    );
    return (
      <React.Fragment>
        <div className="airport-list">
          {airports.length > 0 ? (
            airports.map(([airportId, airport]: [string, any]) =>
              listContent(this, airport, airportId, info[airportId])
            )
          ) : (
            <div className="list-item">
              <div className="airport-name">
                Airports loaded, but no flight are active
              </div>
            </div>
          )}
        </div>
        <ServiceProviderModal
          selectedAirport={this.state.selectedAirport}
          onClose={() => this.setState({ selectedAirport: null })}
        />
      </React.Fragment>
    );
  }
}

export const AirportList = connect((state: AppState) => ({
  realtime: (state.airports || {}).realtime || {},
  info: (state.airports || {}).info || []
}))(AirportListComponent);

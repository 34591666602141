import { combineReducers } from "redux";

import me from "./me";


const rootReducer = combineReducers({
  me,
});

export default rootReducer;

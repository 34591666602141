import React from "react";
import "./service-provider-modal.component.scss";
import {
  ServiceProviderInterface,
  AirportInterface,
} from "../services/airports.service";
import { SpinnerComponent } from "./spinner.component";
import { getCurrentWeather } from "../services/weather.service";

interface ServiceProviderModalProps {
  selectedAirport: any;
  onClose?: () => void;
}

const convertTemperature = (temp: number) => {
  const newTemp = temp - 273.15;
  return Math.round(newTemp);
};

export class ServiceProviderModal extends React.Component<
  ServiceProviderModalProps
> {
  state = {
    weather: null,
  };
  componentDidUpdate(props: ServiceProviderModalProps) {
    if (props.selectedAirport !== this.props.selectedAirport) {
      if (this.props.selectedAirport) {
        document.body.classList.add("modal-open");
        this.getData(this.props.selectedAirport);
      } else {
        document.body.classList.remove("modal-open");
      }
    }
  }
  async getData(airport: any) {
    this.setState({ weather: null });
    const { data } = await getCurrentWeather(airport.info);
    this.setState({ weather: data });
  }
  render() {
    const { selectedAirport, onClose = () => null } = this.props;
    const { weather }: { weather: any } = this.state;

    return (
      <div
        className={`service-provider-modal ${
          !!selectedAirport ? "open" : "closed"
        }`}
      >
        <div className="overlay" onClick={onClose} />

        <div className="body">
          {!!selectedAirport && (
            <React.Fragment>
              <div className="heading">
                <p className="airport-title">
                  {(selectedAirport.info && selectedAirport.info.name) ||
                    "Placeholder name"}
                </p>
                {weather && (
                  <p className="weather-info">
                    {convertTemperature(weather.main.temp)}&deg;C
                    <span>{weather.weather[0].main}</span>
                  </p>
                )}
              </div>
              <div className="service-provider-list">
                {Object.entries(selectedAirport.realtime.service_providers).map(
                  ([serviceProviderId, count]) => {
                    const serviceProvider = (
                      (selectedAirport.info &&
                        selectedAirport.info.service_providers) ||
                      []
                    ).find(
                      (a: ServiceProviderInterface) =>
                        a.id == parseInt(serviceProviderId)
                    );
                    return (
                      <div className="list-item" key={serviceProviderId}>
                        <div className="service-provider-name">
                          {serviceProvider
                            ? serviceProvider.name
                            : serviceProviderId}
                        </div>
                        <div className="counter">{`${count}`}</div>
                      </div>
                    );
                  }
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}


interface AirportsAction {
  type: string
  data: any
  environment: string
  id: string
}

interface AirportsState {
  realtime?: any
  info?: any
}

export const airports = (state: AirportsState = {}, action: AirportsAction) => {
  switch(action.type){
    case '@airports/info/update':
      return {
        ...state,
        info: action.data
      }
    case '@airports/realtime/update':
      return {
        ...state,
        realtime: {
          ...state.realtime || {},
          [action.environment]: action.data
        }
      }
    default:
      return state;
  }
}
import axios from "axios";
import { AirportInterface } from "./airports.service";

const { REACT_APP_OPEN_WEATHER_API } = process.env;

export const getCurrentWeather = (airport: AirportInterface) => {
  if (!airport) {
    airport = {
      latitude: "0",
      longitude: "0",
      id: 0,
      name: "Placeholder airport",
      icao_code: "FAKE",
      service_providers: []
    };
  }
  if (REACT_APP_OPEN_WEATHER_API !== undefined){
    return axios.get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${airport.latitude}&lon=${airport.longitude}&APPID=${REACT_APP_OPEN_WEATHER_API}`
    );
  } else {
    console.warn("Missing REACT_APP_OPEN_WEATHER_API")
    return { data: null }
  }
};

import React from "react";
import Icons from "../../constants";
import "./Password.css";

class Password extends React.Component {
  state = {
    shouldShow: false
  };

  toggleShow(e) {
    e.preventDefault();
    this.setState({ shouldShow: !this.state.shouldShow });
  }

  /* eslint jsx-a11y/no-static-element-interactions: 0 */
  render() {
    const { shouldShow } = this.state;
    const { element, ...props } = this.props;
    return (
      <div className="password-input">
        <span tabIndex={-1} onClick={e => this.toggleShow(e)}>
          {shouldShow ? Icons.hide : Icons.show}
        </span>
        <input ref={e => (element ? element(e) : null)} {...props} type={shouldShow ? "text" : "password"} />
      </div>
    );
  }
}

export default Password;

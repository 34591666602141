import { DateTime } from "luxon";

const { REACT_APP_ICELINK_ENDPOINT } = process.env;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apiUrl: REACT_APP_ICELINK_ENDPOINT,
  timeLoaded: DateTime.utc(),
  routeTypes: {
    globalDesktop: "global-desktop",
    globalMobile: "global-mobile",
    localMobile: "local-mobile",
    localDesktop: "local-desktop",
    pad: "pad",
    dashboard: "dashboard",
  },
  map: {
    tileUrls: {
      LIGHT:
        "https://{a-c}.tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=f3d1e5e796834c0990ce1212a90e453b",
      DARK:
        "https://{a-c}.tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png?apikey=f3d1e5e796834c0990ce1212a90e453b",
    },
  },
  alertTypes: {
    PAD_WIDE_E_STOP: "PAD_WIDE_E_STOP",
    EMB_E_STOP: "EMB_E_STOP",
    PAD_WIDE_LIGHTS_ON: "PAD_WIDE_LIGHTS_ON",
  },
  redux: {
    flight_schedule: {
      LOAD_INITIAL_FLIGHTSTRIPS: "LOAD_INITIAL_FLIGHTSTRIPS",
      ADD_NEW_FLIGHTSTRIP: "ADD_NEW_FLIGHTSTRIP",
      UPDATE_FLIGHTSTRIP: "UPDATE_FLIGHTSTRIP",
      ADD_PARTIAL_FLIGHTSTRIP: "ADD_PARTIAL_FLIGHTSTRIP",
      QUALIFY_PARTIAL_FLIGHTSTRIP: "QUALIFY_PARTIAL_FLIGHTSTRIP",
      UPDATE_DEPARTURE: "UPDATE_DEPARTURE",
      DELETE_DEPARTURE: "DELETE_DEPARTURE",
      DELETE_FLIGHTSTRIP: "DELETE_FLIGHTSTRIP",
      UPDATE_CLOSED_FLIGHTS: "UPDATE_CLOSED_FLIGHTS",
      UPDATE_HIDDEN_FLIGHTS: "UPDATE_HIDDEN_FLIGHTS",
      SET_TODAY_ONLY: "SET_TODAY_ONLY",
      SET_AIRCRAFT_TYPES_MAP: "SET_AIRCRAFT_TYPES_MAP",
      RESET_FLIGHT_SCHEDULE: "RESET_FLIGHT_SCHEDULE",
    },
    pad2: {
      UPDATE_FLIGHT: "UPDATE_FLIGHT",
    }
  },
  smartpad: {
    detection_messages: {
      Advance_1: "detection.advance_1",
      Advance_2: "detection.advance_2",
      Advance_3: "detection.advance_3",
      Advance_4: "detection.advance_4",
      Advance_5: "detection.advance_5",
      Advance_6: "detection.advance_6",
      Advance_7: "detection.advance_7",
      Stop_Set_Park: "detection.stop_set_park",
    },
    redux: {
      dev_detection_message: "DEV_DETECTION_MESSAGE_SEND",
      dev_detection_error: "DEV_DETECTION_ERROR",
    },
  },
  locations: {
    values: {
      GATE: "gate",
      CDF: "cdf",
      STAND: "stand",
      CDF_ICP: "icp",
      CDF_BAY: "bay",
      CDF_OCP: "ocp",
      PAD: "pad",
      QUEUE: "queue",
      STAGING: "staging",
      COMPOSITE: "composite",
    },
    labels: {
      gate: "Gate",
      cdf: "CDF",
      stand: "Stand",
      icp: "ICP",
      bay: "Bay",
      ocp: "OCP",
      pad: "Pad",
      queue: "Queue",
      staging: "Staging",
      composite: "Composite",
    },
    cdfChildTypes: ["icp", "bay", "ocp", "queue", "prequeue", "staging", "composite"],
  },
  user: {
    permissions: {
      mobile: "Mobile",
      deicer: "DeIcer",
      pilot: "Pilot",
      primary: "Primary",
      baylead: "Bay Lead",
      web_primary: "Web Primary",
      dispatch: "Dispatch",
      manager: "Company Admin",
      reporter: "Reporter",
      global: "Global",
      global_sp_admin: "Global Service Provider Admin",
      pad: "Pad Controller",
      station_admin: "Station Admin",
      global_airline: "Global Airline",
      local_service_provider: "Local Service Provider",
      airline_admin: "Global Airline Admin",
      global_service_admin: "Global Service Provider Admin",
      local_airline: "Local Airline",
      trainer: "Trainer",
      airport_authority: "Airport Authority",
    },
    types: {
      global: "global",
      local: "local",
    },
  },
  ndmServiceProvider: {
    name: "No deicing provider",
    slug: "non_deicing_provider",
  },
  flightstrip: {
    surfaceGroups: [
      "Radome",
      "Fuselage",
      "Wings",
      "Vertical Stabilizer",
      "Horizontal Stabilizer",
      "Leading Edges",
      "Undercarriage",
      "Underwings",
      "Engine Cowling",
      "Propellers",
      "Landing Gear",
    ],
    surfaces: {
      radome: "Radome",
      fuselage: "Fuselage",
      "left-wing": "Left Wing",
      "right-wing": "Right Wing",
      "vertical-stabilizer": "Vertical Stabilizer",
      "left-horizontal-stabilizer": "Left Horizontal Stabilizer",
      "right-horizontal-stabilizer": "Right Horizontal Stabilizer",
      "left-wing-leading-edge": "Left Wing Leading Edge",
      "right-wing-leading-edge": "Right Wing Leading Edge",
      "left-stabilizer-leading-edge": "Left Stabilizer Leading Edge",
      "right-stabilizer-leading-edge": "Right Stabilizer Leading Edge",
      undercarriage: "Undercarriage",
      "left-under-wing": "Left Under Wing",
      "right-under-wing": "Right Under Wing",
      "under-left-horizontal-stabilizer": "Under Left Horizontal Stabilizer",
      "under-right-horizontal-stabilizer": "Under Right Horizontal Stabilizer",
      "engine-cowling": "Engine Cowling",
      propellers: "Propellers",
      "landing-gear": "Landing Gear",
    },
    surfaces2groups: {
      "left-wing": "Wings",
      "right-wing": "Wings",
      "left-wing-leading-edge": "Leading Edges",
      "right-wing-leading-edge": "Leading Edges",
      fuselage: "Fuselage",
      "left-horizontal-stabilizer": "Horizontal Stabilizer",
      "right-horizontal-stabilizer": "Horizontal Stabilizer",
      "left-stabilizer-leading-edge": "Leading Edges",
      "right-stabilizer-leading-edge": "Leading Edges",
      "vertical-stabilizer": "Vertical Stabilizer",
      "left-under-wing": "Underwings",
      "right-under-wing": "Underwings",
      "under-left-horizontal-stabilizer": "Underwings",
      "under-right-horizontal-stabilizer": "Underwings",
      "engine-cowling": "Engine Cowling",
      radome: "Radome",
      undercarriage: "Undercarriage",
      propellers: "Propellers",
      "landing-gear": "Landing Gear",
    },
    treatment: {
      deice: [
        // available for de-icing
        "Radome",
        "Fuselage",
        "Wings",
        "Vertical Stabilizer",
        "Horizontal Stabilizer",
        "Leading Edges",
        "Undercarriage",
        "Underwings",
        "Engine Cowling",
        "Propellers",
        "Landing Gear",
      ],
      antiice: [
        // available for anti-icing
        "Fuselage",
        "Wings",
        "Vertical Stabilizer",
        "Horizontal Stabilizer",
        "Leading Edges",
      ],
    },
    inspections: {
      "": "None",
      visual: "Visual",
      tactile: "Tactile",
      underwing: "Underwing",
      other: "Other",
      "engine inlet": "Engine Inlet",
    },
    abortReasons: ["Did Not Deice Aircraft", "Force Flightstrip End"],
  },
  firebase: {
    onTypes: {
      value: "value",
      childAdded: "child_added",
      childChanged: "child_changed",
      childRemoved: "child_removed",
    },
  },
  aircraft: {
    conditions: {
      "clean-aircraft": "Clean Aircraft",
      "pre-emptive": "Pre-Emptive",
      frost: "Frost",
      csff: "CSFF",
      "light-snow": "Light Snow",
      "moderate-snow": "Moderate Snow",
      "heavy-snow": "Heavy Snow",
      "wet-snow": "Wet Snow",
      "rime-ice": "Rime Ice",
      "light-ice": "Light Ice",
      ice: "Ice",
      "clear-ice": "Clear Ice",
      "snow-and-ice": "Snow And Ice",
      other: "Other",
    },

    sizes: [
      // aka icao sizes
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
    ],
  },
  events: {
    labels: {
      "flight-strip-start": "Flight Strip Start",
      "flight-strip-end": "Flight Strip End",
      "flight-strip-cancel": "Flight Strip Cancel",
      "pad-assignment": "Pad Assignment",
      "pad-stop": "Pad Stop",
      "iceman-responsible": "Iceman-Responsible",
      "brakes-set": "Brakes Set",
      "ac-configured-first-step": "Aircraft Configured First Step",
      "aircraft-configured": "Aircraft Configured",
      "truck-movement": "Truck Movement",
      "truck-safe": "Truck Safe",
      "de-icing-process": "De-Icing Process",
      "anti-icing-process": "Anti-Icing Process",
      "override-treatment": "Override Treatment",
      "nozzle-close": "Nozzle Close",
      "treatment-complete": "Treatment Complete",
      "flight-strip-approval": "Flight Strip Approval",
      "sign-in": "Sign In",
      "hold-over-time": "Hold Over Time",
      "fluid-use": "Fluid Use",
      assignment: "Assignment",
      treatment: "Treatment",
      "aircraft-condition": "Aircraft Condition",
      "deicer-join-start": "Deicer Join Start",
      "deicer-join-end": "Deicer Join End",
      "deicer-join-cancel": "Deicer Join Cancel",
      "deicer-accept": "Deicer Accepted",
      "inspection-complete": "Inspection Complete",
      "ctm-override": "CTM Override",
      "lead-remark": "Primary Remark",
      "pilot-remark": "Pilot Remark",
      "crew-assignment-start": "Crew Assignment Start",
      "crew-assignment-end": "Crew Assignment End",
      "crew-assignment-cancel": "Crew Assignment Cancel",
      "cdf-icp": "CDF ICP",
      "cdf-staging": "CDF Staging",
      "cdf-bay": "CDF Bay",
      "cdf-composite": "CDF Composite",
      "cdf-ocp": "CDF OCP",
      "cdf-queue": "CDF Queue",
      "cdf-park": "CDF Park",
      "cdf-prequeue": "CDF Prequeue",
      "pilot-briefed": "Pilot Briefed",
      quadrants: "Quadrants",
      location: "Location",
      "pilot-communication": "Pilot Communication",
      "local-spot-deicing": "Local Spot Deicing Complete",
    },
  },
  // Default JSON object in case thresholds have never been configured
  default_thresholds: {
    deice_thresholds: {
      A: {
        "clean-aircraft": 0,
        "light-snow": 0,
        frost: 0,
        "clear-ice": 0,
        "rime-ice": 0,
        "moderate-snow": 0,
        "wet-snow": 0,
        "heavy-snow": 0,
        "snow-and-ice": 0,
        ice: 0,
        other: 0,
      },
      B: {
        "clean-aircraft": 0,
        "light-snow": 0,
        frost: 0,
        "clear-ice": 0,
        "rime-ice": 0,
        "moderate-snow": 0,
        "wet-snow": 0,
        "heavy-snow": 0,
        "snow-and-ice": 0,
        ice: 0,
        other: 0,
      },
      C: {
        "clean-aircraft": 0,
        "light-snow": 0,
        frost: 0,
        "clear-ice": 0,
        "rime-ice": 0,
        "moderate-snow": 0,
        "wet-snow": 0,
        "heavy-snow": 0,
        "snow-and-ice": 0,
        ice: 0,
        other: 0,
      },
      D: {
        "clean-aircraft": 0,
        "light-snow": 0,
        frost: 0,
        "clear-ice": 0,
        "rime-ice": 0,
        "moderate-snow": 0,
        "wet-snow": 0,
        "heavy-snow": 0,
        "snow-and-ice": 0,
        ice: 0,
        other: 0,
      },
      E: {
        "clean-aircraft": 0,
        "light-snow": 0,
        frost: 0,
        "clear-ice": 0,
        "rime-ice": 0,
        "moderate-snow": 0,
        "wet-snow": 0,
        "heavy-snow": 0,
        "snow-and-ice": 0,
        ice: 0,
        other: 0,
      },
      F: {
        "clean-aircraft": 0,
        "light-snow": 0,
        frost: 0,
        "clear-ice": 0,
        "rime-ice": 0,
        "moderate-snow": 0,
        "wet-snow": 0,
        "heavy-snow": 0,
        "snow-and-ice": 0,
        ice: 0,
        other: 0,
      },
    },
    anti_thresholds: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      E: 0,
      F: 0,
    },
  },
  weather: {
    weatherMap: {
      Clear: "NSW // CLR",
      Overcast: "NSW // X CLD OVC",
      "Active Frost": "-FZUP FROIN ICG // ACTV FRST",
      "Very Light Snow": "-SN P0000 // VRY LGT SNOW",
      "Light Snow": "-SN // LGT SNOW",
      "Moderate Snow": "SN // MDT SNOW",
      "Heavy Snow": "+SN // HVY SNOW",
      Hail: "GR // HAIL",
      "Freezing Fog": "FZFG // FRZ FOG",
      "Freezing Drizzle": "FZDZ // FRZ DRZL",
      Rain: "RA // RAIN",
      "Ice Pellets": "PL // ICE PEL",
      "Light Freezing Rain": "-FZRA // LGT FRZ RAIN",
      "Moderate Freezing Rain": "FZRA // MDT FRZ RAIN",
      "Heavy Freezing Rain": "+FZRA // HVY FRZ RAIN",
    },
    expiration_choices: [
      { key: 60, value: "1 hr" },
      { key: 15, value: "15 min" },
      { key: 30, value: "30 min" },
      { key: 45, value: "45 min" },
      { key: 120, value: "2 hrs" },
      { key: 180, value: "3 hrs" },
    ],
    icons: {
      Clear: "wi-day-sunny",
      "Very Light Snow": "wi-day-snow",
      "Light Snow": "wi-day-snow",
      "Moderate Snow": "wi-snow",
      "Heavy Snow": "wi-snow",
      "Active Frost": "wi-strong-wind",
      Hail: "wi-day-hail",
      "Freezing Fog": "wi-night-cloudy-gusts",
      "Freezing Drizzle": "wi-night-sprinkle",
      Rain: "wi-rain-mix",
      "Ice Pellets": "wi-night-storm-showers",
      "Light Freezing Rain": "wi-light-showers",
      "Moderate Freezing Rain": "wi-storm-showers",
      "Heavy Freezing Rain": "wi-thunderstorm",
      Overcast: "wi-day-cloudy",
    },
    conditions: [
      "Clear",
      "Very Light Snow",
      "Light Snow",
      "Moderate Snow",
      "Heavy Snow",
      "Active Frost",
      "Hail",
      "Freezing Fog",
      "Freezing Drizzle",
      "Rain",
      "Ice Pellets",
      "Light Freezing Rain",
      "Moderate Freezing Rain",
      "Heavy Freezing Rain",
      "Overcast",
    ],
    table: [
      ["Clear", "Overcast", "Very Light Snow"],
      ["Light Snow", "Moderate Snow", "Heavy Snow", "Active Frost"],
      ["Hail", "Freezing Fog", "Freezing Drizzle", "Rain"],
      ["Ice Pellets", "Light Freezing Rain", "Moderate Freezing Rain", "Heavy Freezing Rain"],
    ],
  },
  vehicles: {
    tank_types: [
      ["no_fluid", "No Tanks"],
      ["deice_only", "Deice Tank Only"],
      ["both_fluids", "Deice and Anti-ice Tanks"],
    ],
  },
  dashboard: {
    bunches: {
      gate_pad: "gate_pad",
      hourly: "hourly",
      size: "size",
      zones: "zones",
      locations: "locations",
    },
    location_types: { cdf: "CDF", gate: "Gate", all: "All" },
    redux: {
      UPDATE_DASHBOARD_DATA: "UPDATE_DASHBOARD_DATA",
    },
  },
  reports: {
    headers: [
      {
        header: "Request Date",
        width: 16,
        key: "Request Date",
        numFmt: "dd.mm.yyyy",
      },
      {
        header: "Request Time",
        width: 16,
        key: "Request Time",
        numFmt: "hh:mm:ss",
      },
      {
        header: "Airline",
        width: 16,
        key: "Airline",
      },
      {
        header: "Flight Number",
        width: 12,
        key: "Flight Number",
      },
      {
        header: "Registration",
        width: 12,
        key: "Registration",
      },
      {
        header: "A/C Type",
        width: 12,
        key: "A/C Type",
      },
      {
        header: "A/C Size",
        width: 8,
        key: "A/C Size",
      },
      {
        header: "OAT",
        width: 8,
        key: "OAT",
        numFmt: "0.00",
      },
      {
        header: "Weather",
        width: 12,
        key: "Weather",
      },
      {
        header: "Contamination",
        width: 14,
        key: "Contamination",
      },
      {
        header: "Scheduled Pushback",
        width: 22,
        key: "Scheduled Pushback",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Actual Pushback",
        width: 20,
        key: "Actual Pushback",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "ICP",
        width: 20,
        key: "ICP",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Brakes Set",
        width: 20,
        key: "Brakes Set",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "A/C Config",
        width: 20,
        key: "A/C Config",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Inspection Time",
        width: 20,
        key: "Inspection Time",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Inspection Type",
        width: 14,
        key: "Inspection Type",
      },
      {
        header: "Deice Start",
        width: 20,
        key: "Deice Start",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "HOT Start",
        width: 20,
        key: "HOT Start",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Anti-ice Start",
        width: 20,
        key: "Anti-ice Start",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "End Spray",
        width: 20,
        key: "End Spray",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Flight Complete",
        width: 20,
        key: "Flight Complete",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "OCP",
        width: 20,
        key: "OCP",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Wheels Up",
        width: 20,
        key: "Wheels Up",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Number of Trucks",
        width: 18,
        key: "Number of Trucks",
        numFmt: "0",
      },
      {
        header: "Deice Sprayed",
        width: 18,
        key: "Deice Sprayed",
        numFmt: "0.0",
      },
      {
        header: "Deice Concentration",
        width: 18,
        key: "Deice Concentration",
        numFmt: "0%",
      },
      {
        header: "Anti-ice Sprayed",
        width: 18,
        key: "Anti-ice Sprayed",
        numFmt: "0.0",
      },
      {
        header: "Anti-ice Concentration",
        width: 18,
        key: "Anti-ice Concentration",
        numFmt: "0%",
      },
      {
        header: "Metar",
        width: 30,
        key: "Metar",
      },
      {
        header: "Line Up Time",
        width: 20,
        key: "Line Up Time",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Throughput Times",
        width: 20,
        key: "Throughput Times",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Treatment Time",
        width: 20,
        key: "Treatment Time",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Spray Time",
        width: 20,
        key: "Spray Time",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Time to Fly",
        width: 20,
        key: "Time to Fly",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
      {
        header: "Out to Off",
        width: 20,
        key: "Out to Off",
        numFmt: "dd.mm.yyyy hh:mm:ss",
      },
    ],
    time_headers: [
      "Request Date",
      "Request Time",
      "Scheduled Pushback",
      "Actual Pushback",
      "ICP",
      "Brakes Set",
      "A/C Config",
      "Inspection Time",
      "Deice Start",
      "HOT Start",
      "Anti-ice Start",
      "End Spray",
      "Flight Complete",
      "OCP",
      "Wheels Up",
    ],
    datetime_keys: [
      "anti_ice_treatment_start_datetime",
      "deice_start_time",
      "treatment_start_datetime",
      "treatment_complete_datetime",
      "hold_over_datetime",
      "inspection_datetime",
      "nozzle_close_time",
    ],
    forage_keys: {
      done: "doneList",
      in_progress: "inProgressList",
    },
    notifications: {
      new_report: {
        message: "A new report is ready for download",
        type: "success",
      },
      error: {
        message: "Failed to generate report due to an error.",
        type: "error",
      },
    },
    report_types: {
      kpi: "performance-report",
      fluid: "fluid-report",
      archive: "archive-report",
      displayed_data: "displayed-data",
    },
    global_comparison: {
      session_storage_key: "report-global-airport-values",
      filter_types: {
        line_up_time_avg: "time",
        spray_time_avg: "time",
        treatment_time_avg: "time",
        start_delta_avg: "time",
        cdf_throughput_time_avg: "time",
        out_to_off_time_avg: "time",
        deice_fluid_used_avg: "fluid",
        antiice_fluid_used_avg: "fluid",
        deice_fluid_used: "fluid",
        antiice_fluid_used: "fluid",
        trucks_per_flight_avg: "round",
      },
      airport_types: [
        { label: "Hub", value: "hub" },
        { label: "Large", value: "large" },
        { label: "Medium", value: "medium" },
        { label: "Small", value: "small" },
      ],
      column_types: [
        {
          header: "Station",
          key: "airport_icao_code",
          width: 8,
        },
        {
          header: "Total Flights Serviced",
          key: "aircraft_serviced",
          width: 18,
        },
        {
          header: "Average Line Up Time",
          key: "line_up_time_avg",
          width: 18,
          numFmt: "hh:mm",
        },
        {
          header: "Average Treatment Time",
          key: "treatment_time_avg",
          width: 20,
          numFmt: "hh:mm",
        },
        {
          header: "Average Spray Time",
          key: "spray_time_avg",
          width: 18,
          numFmt: "hh:mm",
        },
        {
          header: "Avg. Deice Fluid (GAL)",
          key: "deice_fluid_used_avg",
          width: 20,
        },
        {
          header: "Avg. Anti-ice Fluid (GAL)",
          key: "antiice_fluid_used_avg",
          width: 20,
        },
        {
          header: "Avg. Trucks Per Flight",
          key: "trucks_per_flight_avg",
          width: 20,
        },
        {
          header: "Total Deice (GAL)",
          key: "deice_fluid_used",
          width: 16,
        },
        {
          header: "Total Anti-ice (GAL)",
          key: "antiice_fluid_used",
          width: 16,
        },
        {
          header: "Out to Off",
          key: "out_to_off_time_avg",
          width: 16,
          numFmt: "hh:mm",
        },
        {
          header: "Throughput Time",
          key: "cdf_throughput_time_avg",
          width: 16,
          numFmt: "hh:mm",
        },
        {
          header: "S2S",
          key: "start_delta_avg",
          width: 16,
          numFmt: "hh:mm",
        },
      ],
    },
  },
  embNoStopGoModuleAirports: ['EHAM'],
  alt_feed: {
    airportIds: [23],
  },
};

import React from "react";
import { withRouter } from 'react-router-dom'; // Import withRouter
import * as AuthService from "../../common/services/auth";
import FormGroup from "../../common/components/formGroup";
import Password from "../../common/components/Password";
import "./signin.css";

class Signin extends React.Component {
  state = {
    formErrors: {},
    status: null
  };

  submitForm = (e) => {
    e.preventDefault();
    const username = this.username.value;
    const password = this.password.value;
    this.setState({ status: "Authenticating", formErrors: {} });
    AuthService.authenticate({ username, password, grant_type: "password" })
      .then(() => {
        this.getCurrentUser();
        // Redirect to /production upon successful authentication
        this.props.history.push('/production');
      })
      .catch(error => {
        this.setState({ formErrors: error.response.data });
      })
      .finally(() => this.setState({ status: null }));
  };

  getCurrentUser() {
    return AuthService.currentUser()
      .then(({ data }) => {
        if (data && !data.is_password_reset) {
          this.props.onResetPassword(data.username);
          return;
        }

        if (data.error) {
          this.setState({ formErrors: data.error });
          return;
        }
        AuthService.redirectUser(data.groups || [], data);
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          this.setState({ formErrors: error.response.data.error });
        }
      });
  }

  componentDidMount() {
    if (this.props.username) {
      this.username.value = this.props.username;
    }
    this.checkIfUserIsLoggedIn();
  }

  checkIfUserIsLoggedIn() {
    return AuthService.currentUser().then(({ data }) => {
      if (!data.error) {
        if (!data.is_password_reset) {
          this.props.onResetPassword(data.username);
        } else {
          AuthService.redirectUser(data.groups || [], data);
        }
      }
    });
  }

  render() {
    const { formErrors, status } = this.state;
    return (
      <div className="container">
        <div className="box" id="signinContainer">
          <div className="card">
            <div className="card-header">Sign In</div>
            <div className="card-body">
              {this.props.message && <p className="alert alert-success">{this.props.message}</p>}
              <form onSubmit={e => this.submitForm(e)}>
                <FormGroup label="Username" htmlFor="username">
                  <input
                    type="text"
                    id="username"
                    name="username"
                    autoComplete="username"
                    className="form-control"
                    ref={i => {
                      this.username = i;
                    }}
                  />
                </FormGroup>
                <FormGroup label="Password" htmlFor="password">
                  <Password
                    id="current-password"
                    name="password"
                    className="form-control"
                    autoComplete="current-password"
                    element={i => {
                      this.password = i;
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <input id="signin" type="submit" className="btn btn-success mr-auto" value="Sign In" />
                  {status && <span className="status ml-2">{status}</span>}
                </FormGroup>
                {(formErrors.error_description || formErrors.message) && (
                  <p className="alert alert-danger">{formErrors.error_description || formErrors.message}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Signin); // Wrap the Signin component with withRouter to access the history object

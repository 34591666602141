import { hasJcaiiInternalUserPermission } from "../../common/components/HasPermission";
import { store } from "../../common/services/utils";
// eslint-disable-next-line import/no-cycle
import Http, { cleanCachedData } from "../../common/services/http";

const {REACT_APP_ICELINK_CLIENT_ID, REACT_APP_ICELINK_CLIENT_SECRET, ual_logout = null } = process.env;

const authCredentials = {
  username: REACT_APP_ICELINK_CLIENT_ID,
  password: REACT_APP_ICELINK_CLIENT_SECRET,
};

// Authenticate a user
export const authenticate = (credentials, saml_login = false) =>

  Http.post("/api/o/token/", credentials, { auth: authCredentials }).then(resp => {
    // If the request is successfull store
    // 'access_token' & 'refresh_token'
    const { access_token, refresh_token, username } = resp.data;
    window.localStorage.removeItem("default-labels");
    if (saml_login) {
      window.localStorage.setItem("saml_login", "1");
    } else {
      window.localStorage.removeItem("saml_login");
    }
    window.localStorage.setItem("access_token", access_token);
    window.localStorage.setItem("refresh_token", refresh_token);
    window.localStorage.setItem("username", username);
    cleanCachedData();
    return resp;
  });

export const getUsername = () => window.localStorage.getItem("username");

const serializeUser = data => {
  const editableFields = [
    "email",
    "prefer_airport_time",
    "mobile",
    "first_name",
    "last_name",
    "birthdate",
    "gender",
  ];
  const res = {};
  editableFields.forEach(field => {
    res[field] = data[field];
  });
  return res;
};

export const currentUser = () => {
  const username = getUsername();
  if (username) {
    return Http.get(`/api/3/users/${username}/`)
      .then(data => {
        return data;
      })
      .catch(e => {
        if (e.response.data.code === "password_change_required") {
          window.location.href = "/admin/#/settings/?s=password";
        } else if (e.response.data.code === "not_found") {
          // eslint-disable-next-line no-use-before-define
          logoutUser();
        }
        throw e;
      });
  }
  return new Promise(() => {});
};

export const updateCurrentUser = (data = {}) =>
  Http.patch(`/api/3/user-profile/${getUsername()}/`, serializeUser(data)).then(resp => {
    const { data: authData } = resp;
    store.dispatch({ type: "SET_CURRENT_USER", data: { ...store.getState().me.user, ...authData } });
    return resp;
  });

export const changePassword = data =>
  Http.put(`/api/3/users-password/${getUsername()}/`, data).then(resp => resp);

export const refreshAccessToken = () => {
  // This makes sure we dont try to refresh the token more than once.
  window.localStorage.setItem("token_refreshing", true);
  const refreshToken = process.localStorage.getItem("refresh_token");
  return Http.post(
    "/api/o/token/",
    { grant_type: "refresh_token", refresh_token: refreshToken },
    { auth: authCredentials }
  ).then(resp => {
    // Remove the token refreshing flag
    window.localStorage.removeItem("token_refreshing");
    // If the request is successfull store
    // 'access_token' & 'refresh_token'
    const { access_token, refresh_token, username } = resp.data;
    window.localStorage.setItem("access_token", access_token);
    window.localStorage.setItem("refresh_token", refresh_token);
    window.localStorage.setItem("username", username);
    return resp;
  });
};

export const logoutUser = () => {
  window.localStorage.removeItem("access_token");
  window.localStorage.removeItem("refresh_token");
  window.localStorage.removeItem("username");
  sessionStorage.removeItem("report-global-airline-values");
  sessionStorage.removeItem("flight-schedule-filter-airlines");
  cleanCachedData();
  if (window.localStorage.getItem("saml_login") && ual_logout) {
    window.localStorage.removeItem("saml_login");
    window.location.replace(ual_logout);
  } else {
    window.location.assign("/signin");
  }
};

export const redirectUser = (user) => {
  if (
    (user && hasJcaiiInternalUserPermission(user))
  ) {
    window.location.assign("/production");
    return;
  }
};

export const token = () => window.localStorage.getItem("access_token");

import React from 'react';
import { connect } from 'react-redux';
import './counter.component.scss';
import { AppState, AirportsState } from '../reducers';

interface FlightstripCounterProps {
  realtime: any
  environment: string
}

class FlightstripCounterComponent extends React.Component<FlightstripCounterProps> {
  state = {};
  getCount(props: FlightstripCounterProps){
    const { environment, realtime } = props;
    const airportData = realtime[environment] || {};
    return Object.keys(airportData).reduce((total, airportId) => {
      const airport = airportData[airportId];
      return total + airport.count;
    }, 0);
  }
  render(){
    const count = this.getCount(this.props);
    return (
      <div className="flightstrip-counter">
        <p className="count">{count}</p>
        <p className="label">Flightstrips</p>
      </div>
    );
  }
}

export const FlightstripCounter = connect((state: AppState) => ({
  realtime: (state.airports || {}).realtime || {}
}))(FlightstripCounterComponent)